<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1(
        :color="itemColor"
        :subtitle="campaignCustomerAgent.friendly_start_time"
    )

        template(v-slot:title)
            span(:class="campaignCustomerAgent.status_name | statusClassMatcher") {{ campaignCustomerAgent.status_name }}

        template(v-slot:subtitle)
            span.black--text
                | {{ campaignCustomerAgent.friendly_start_time }}

        template(v-slot:rightBottom)
            span.black--text
                | {{ campaignCustomerAgent.duration | friendlyDurationFormat }}

        template(v-slot:rightTop)
            v-icon(small) mdi-account
            span.black--text
                | {{ campaignCustomerAgent.user_full_name }}
</template>

<script lang="ts">
import Vue from 'vue';

// Components
import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
import ListItem from '@/components/shared/list_item.vue';

// Helpers
import { resourceColorClass } from "@/helpers/css_classes";
import {statusClassMatcher} from "@/helpers/css_classes";

export default Vue.extend({
    data() {
        return {
            showDetails: false,
        }
    },
    methods: {

    },
    computed: {
        itemColor() {
            return resourceColorClass("AgentStatuses");
        },
        validCampaignCustomerAgentStatusType() {
            return this.campaignCustomerAgent?.status_type?.length > 0;
        },
        validEmailEvent() {
            return this.emailEvent?.vid > 0;
        },
    },
    components: {
        ExpandableCardListItem,
        ListItem,
    },
    props: {
        campaignCustomerAgent: Object,
    },
});
</script>

<style lang="scss" scoped>
#app {
    .cell-value-spacing {
        padding: 0 0 5px 0;
    }
}
</style>
