<template lang="pug">
    #compose-email
        v-overlay(:value='overlayLoader')
            v-progress-circular(indeterminate size='100')

        v-toolbar(height="48px")
            v-btn.mx-2(icon dark small color='error' ripple @click="exit(false);")
                v-icon(dark) mdi-close-circle-outline
            v-btn.mx-2(icon dark small color="success" ripple @click="createEmail();")
                v-icon(dark) mdi-send
            v-spacer
            template(v-if="validEmailAttachments")
                .font-weight-bold(v-if="totalEmailAttachments === 1") {{ totalEmailAttachments | number }} Attachment
                .font-weight-bold(v-else) {{ totalEmailAttachments | number }} Attachments
            v-btn.mx-2(icon light small ripple @click="openAttachmentInput();")
                v-icon(dark) mdi-paperclip
            v-file-input#email-attachment-input(
                accept="audio/*,video/*,image/*,.csv,.doc,.docx,.pdf,.txt,.rtf,.html,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.mkv,.ppt,.pptx"
                multiple
                style="display: none;"
                @change="onEmailAttachmentInputChange();"
                v-model="newAttachmentFiles"
            )

        v-card-text(
            style="overflow-y: auto;"
            v-bind:style="{ height: mainClientHeight + 'px' }"
        )
            v-form(ref='form' style="margin-top: 10px;")
                v-combobox(
                    v-model='newEmail.to'
                    label='To'
                    dense
                    multiple
                    chips
                    clearable
                    deletable-chips
                    no-filter
                    @change="validateToRecipient"
                )

                v-combobox(
                    v-model='newEmail.cc'
                    label='Cc'
                    dense
                    multiple
                    chips
                    clearable
                    deletable-chips
                    no-filter
                    @change="validateCcRecipient"
                )

                v-combobox(
                    v-model='newEmail.bcc'
                    label='Bcc'
                    dense
                    multiple
                    chips
                    clearable
                    deletable-chips
                    no-filter
                    @change="validateBccRecipient"
                )

                v-text-field(
                    label='Subject'
                    type='text'
                    v-model="newEmail.subject"
                )

                v-text-field(
                    label='From'
                    type='text'
                    v-model="newEmail.from"
                )

            v-divider                         
            
            //- v-col
            //-     v-row
            //-         v-btn(@click="displayTranslationPicker = !displayTranslationPicker" icon :class="{'grey-background': displayTranslationPicker}")
            //-             v-icon mdi-translate
            //-         v-btn(@click="displayTranslationPicker = !displayTranslationPicker" icon :class="{'grey-background':!displayTranslationPicker}")
            //-             v-icon mdi-email-fast-outline
            //-         v-spacer         
            //-         template(v-if="displayTranslationPicker")
            //-             TranslationPicker(
            //-                 :translate-message="translateMessage"
            //-             )
            //-         template(v-else)
            //-             QuickReplyTiles(
            //-                 :quick-reply="quickReply"
            //-             )

            //- template(v-if="llmResponseIsLoading")
            //-     div.text-center
            //-         v-progress-circular(
            //-             :size='100'
            //-             :width='10'
            //-             color='#0f5b9a'
            //-             indeterminate
            //-             style="margin-top: 75px; margin-bottom: 75px;"
            //-         )

            template
                tiptap-vuetify(
                    v-model='newEmail.body'
                    :extensions='extensions'
                )

                template(v-if="validEmailAttachments")
                    v-divider
                    v-row(
                        v-if="validAttachmentRows"
                        v-for='(row, rowIndex) in attachmentRows'
                        :key='rowIndex'
                    )
                        v-col(
                            cols="4"
                            v-for='(attachment, attachmentIdx) in row'
                            :key='attachmentIdx'
                        )
                            v-hover
                                template(v-slot:default='{ hover }')
                                    v-card.mx-auto(
                                        :elevation="hover ? 12 : 2"
                                    )
                                        v-card-text
                                            v-img(
                                                contain
                                                max-height='60'
                                                min-height='60'
                                                max-width='120'
                                                min-width='120'
                                                :src='attachment'
                                            )
                                        v-card-subtitle {{ displayAttachmentName(attachment) | str_limit(50) }}
                                        v-fade-transition
                                            v-overlay(v-if='hover' absolute color='#999999')
                                                v-btn(
                                                    light
                                                    fab
                                                    small
                                                    ripple
                                                    @click="removeAttachment(attachmentIdx);"
                                                )
                                                    v-icon mdi-close

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";
    import $ from 'jquery';

    // Components
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from 'tiptap-vuetify';

    // Data
    import {
        CreateAssets,
        CreateEmail,
        CreateQuickReply,
        CreateTranslation,
    } from "@/data";

    // Helpers
    import { cleanupEmailAddress } from "@/helpers/email";
    import TranslationPicker from '@/components/communication_center/sms_message/translation_picker.vue';
    import QuickReplyTiles from '@/components/communication_center/email/quick_reply_tiles.vue';

    export default Vue.extend({
        data() {
            return {
                attachmentRows: [],
                bus: VolieState.websocketClient().eventBus(),
                displayTranslationPicker: false,
                editorMinHeight: '300px',
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Paragraph,
                    HardBreak,
                ],
                llmResponseIsLoading: false,
                newAttachmentFiles: null,
                newEmail: null,
                overlayLoader: false,
            }
        },
        methods: {
            buildAttachmentRows() {

                // Init
                this.attachmentRows = [];

                // Ensure we have attachments
                if (!this.validEmailAttachments) {
                    return;
                }

                const MAX_PER_ROW = 3;

                // Loop through attachments and fill in rows
                for (let i = 0; i < this.newEmail.attachments.length; i++) {

                    // Init
                    const attachment = this.newEmail.attachments[i];

                    // If the last row has less attachments than the max per row, push onto it
                    // Otherwise start a new row
                    const lastRow = this.attachmentRows[this.attachmentRows.length -1];
                    if (lastRow?.length < MAX_PER_ROW) {
                        lastRow.push(attachment);
                    } else {
                        this.attachmentRows.push([attachment]);
                    }
                }
            },
            createEmail() {

                // Ensure
                if (!this.eligibleToSendEmail) {
                    this.showSnackbar("error", "A valid to recipient, from address, and email body MUST be present to send email.");
                    return;
                }

                // Init
                const options = {
                    account_id: (this.accountId || this.currentCampaignCustomer?.account_id || 0),
                    attachments: (this.newEmail.attachments || []),
                    bcc: this.newEmail.bcc,
                    body: this.newEmail.body,
                    campaign_customer_active_id: (this.currentCampaignCustomer?.campaign_customer_active_id || 0),
                    cc: this.newEmail.cc,
                    customer_id: (this.customerId || this.currentCampaignCustomer?.customer_id || 0),
                    from: this.newEmail.from,
                    other_campaign_customer_id: (this.otherCampaignCustomerId || 0),
                    parent_email_id: (this.parentEmailId || 0),
                    subject: this.newEmail.subject,
                    to: this.newEmail.to,
                }

                this.overlayLoader = true;
                CreateEmail(options).then((response) => {
                    if (response.Data?.email?.id > 0) {
                        this.showSnackbar("success", "Successfully sent email");
                        this.playAudio("audioEmailOutcoming");
                        if (this.validCreateAgentForSentEmail) {
                            this.createAgentForSentEmail(response.Data.email);
                        }
                        this.exit(true);
                    } else {
                        this.showSnackbar("error", "An error occurred while sending email");
                    }
                    this.overlayLoader = false;
                });
            },
            displayAttachmentName(attachmentUrl) {

                // Verify
                if (!attachmentUrl) {
                    return;
                }

                // Split
                const pieces = attachmentUrl.split("/");
                if (!(pieces?.length > 0)) {
                    return;
                }

                // Return last part of url
                return pieces[pieces.length - 1];
            },
            initNewEmail() {

                // Init
                this.newEmail = {
                    attachments: [],
                    bcc: [],
                    body: null,
                    cc: [],
                    from: null,
                    subject: null,
                    to: [],
                }

                // cc
                if (this.cc?.length > 0) {
                    this.newEmail.cc = this.cc;
                }

                // from
                if (this.from?.length > 0) {
                    this.newEmail.from = this.from;
                }

                // subject
                if (this.subject?.length > 0) {
                    this.newEmail.subject = this.subject;
                }

                // to
                if (this.to?.length > 0) {
                    this.newEmail.to = this.to;
                } else if (this.customerEmailAddressEmail?.length > 0) {
                    this.newEmail.to = [this.customerEmailAddressEmail];
                }
            },
            onEmailAttachmentInputChange() {

                // Verify valid files
                if (!this.validNewEmailAttachmentFiles) {
                    return;
                }

                // Set overlay loader
                this.overlayLoader = true;

                // Init formData
                const formData = new FormData();

                // Set common fields
                formData.set("account_id", (this.accountId || this.currentCampaignCustomer?.account_id || 0));
                formData.set("asset_id", (this.customerId || this.currentCampaignCustomer?.customer_id || 0));
                formData.set("asset_type", "customer");

                // Loop over files and append to formData
                for (let i = 0; i < this.newAttachmentFiles.length; i++) {

                    // Append
                    // Key is 'file-1', 'file-2', etc
                    formData.append("file-" + (i + 1), this.newAttachmentFiles[i]);
                }

                // Upload assets
                CreateAssets(formData).then((response) => {
                    if (response.Data?.assets?.length > 0) {

                        // Loop through assets and add urls to email attachments
                        for(let i = 0; i < response.Data.assets.length; i++) {

                            // Init
                            const asset = response.Data.assets[i];
                            if (!(asset?.asset_url?.length > 0)) {
                                continue;
                            }

                            // Check if we already have this asset
                            if (this.newEmail.attachments.indexOf(asset.asset_url) >= 0) {
                                continue;
                            }

                            // Append
                            this.newEmail.attachments.push(asset.asset_url);
                        }
                    }

                    // Empty new attachment files
                    this.newAttachmentFiles = [];

                    // Remover loader
                    this.overlayLoader = false;
                });
            },
            openAttachmentInput() {
                $("#email-attachment-input")?.click();
            },
            playAudio(key) {
                if (this.userDomInteraction === true && key?.length > 0 && !!this[key]) {

                    // Rewind current audio back to the start since this audio object is only loaded once
                    this[key].currentTime = 0;

                    // Set volume to 50%
                    this[key].volume = 0.5;

                    // Play audio
                    this[key].play();
                }
            },
            quickReply(responseType: string) {
                if (responseType === "" || responseType == null) {
                    return
                }

                const options = {
                    response_type: responseType,
                    message: this.replyToEmailBody,
                }

                this.llmResponseIsLoading = true;
                CreateQuickReply(options).then((response) => {

                    if (response?.Data?.message != "") {
                    this.newEmail.body = response.Data.message
                    } else {
                        let err = "Unable to create quick reply"
                        if (response?.Error?.length > 0) {
                            err = response.Error
                        }
                        this.showSnackbar("error", err);
                    }

                    this.displayTranslationPicker= true;
                    this.llmResponseIsLoading = false;
                });
            },
            removeAttachment(idx) {

                // Verify
                if (!(idx >= 0) || !this.validEmailAttachments) {
                    return;
                }

                // Remove
                this.newEmail.attachments.splice(idx, 1);
            },
            translateMessage(language: string) {
                if (this.newEmail === null || this.newEmail.body === "" || this.newEmail.body === null || language === "") {
                    return
                }

                const options = {
                    language: language,
                    message: this.newEmail.body,
                }

                this.llmResponseIsLoading = true;
                CreateTranslation(options).then((response) => {
                    if (response?.Data?.message != "") {
                    this.newEmail.body = response.Data.message
                    } else {
                        let err = "Unable to translate message"
                        if (response?.Error?.length > 0) {
                            err = response.Error
                        }
                        this.showSnackbar("error", err);
                    }

                    this.displayTranslationPicker= false;
                    this.llmResponseIsLoading = false;
                });
            },
            validateBccRecipient() {

                // Verify
                if (!(this.newEmail.bcc?.length > 0)) {
                    return;
                }

                // Get last input
                const recipient = this.validateRecipient(this.newEmail.bcc[this.newEmail.bcc.length - 1]);
                if (!(recipient?.length > 0)) {

                    // Render error
                    this.showSnackbar("red", "Valid format is: 'emailaddress' OR 'Name <emailAddress>'");

                    // Remove last element
                    this.newEmail.bcc.splice(this.newEmail.bcc.length - 1, 1);
                }

                // Put cleanup up as input
                this.newEmail.bcc[this.newEmail.bcc.length - 1] = recipient;
            },
            validateCcRecipient() {

                // Verify
                if (!(this.newEmail.cc?.length > 0)) {
                    return;
                }

                // Get last input
                const recipient = this.validateRecipient(this.newEmail.cc[this.newEmail.cc.length - 1]);
                if (!(recipient?.length > 0)) {

                    // Render error
                    this.showSnackbar("red", "Valid format is: 'emailaddress' OR 'Name <emailAddress>'");

                    // Remove last element
                    this.newEmail.cc.splice(this.newEmail.cc.length - 1, 1);
                }

                // Put cleanup up as input
                this.newEmail.cc[this.newEmail.cc.length - 1] = recipient;
            },
            validateRecipient(input) {

                // Verify
                if (!(input?.length > 0)) {
                    return "";
                }

                // First trim
                const cleaned = input?.trim();
                if (!(cleaned?.length > 0)) {
                    return "";
                }

                // See if they put a name in
                if (cleaned.indexOf("<") >= 0 && cleaned.indexOf(">") >= 0) {

                    // Split at <
                    const pieces = cleaned.split("<");

                    // Should be two pieces
                    if (pieces?.length !== 2) {
                        return "";
                    }

                    // index 1 should be email address, remove > and valiate
                    const emailAddress = cleanupEmailAddress(pieces[1].replace(">", ""));
                    if (!(emailAddress?.length > 0)) {
                        return "";
                    }

                    return pieces[0].trim() + " <" + emailAddress + ">";
                }

                // Otherwise, must be a plain email address
                return cleanupEmailAddress(cleaned);
            },
            validateToRecipient() {

                // Verify
                if (!(this.newEmail.to?.length > 0)) {
                    return;
                }

                // Get last input
                const recipient = this.validateRecipient(this.newEmail.to[this.newEmail.to.length - 1]);
                if (!(recipient?.length > 0)) {

                    // Render error
                    this.showSnackbar("red", "Valid format is: 'emailaddress' OR 'Name <emailAddress>'");

                    // Remove last element
                    this.newEmail.to.splice(this.newEmail.to.length - 1, 1);
                }

                // Put cleanup up as input
                this.newEmail.to[this.newEmail.to.length - 1] = recipient;
            },
        },
        computed: {
            eligibleToSendEmail() {
                return this.newEmail?.to?.length > 0 && this.newEmail?.from?.length > 0 && this.newEmail?.body?.length > 0;
            },
            replyToEmailBody() {
                if (this.composingOptions.direction === "incoming") {
                    return this.composingOptions.body;
                }
                return ""
            },
            totalEmailAttachments() {
                return (this.newEmail?.attachments?.length || 0);
            },
            userDomInteraction() {
                return VolieState.userDomInteraction();
            },
            validAttachmentRows() {
                return this.attachmentRows?.length > 0;
            },
            validCreateAgentForSentEmail() {
                return this.createAgentForSentEmail !== undefined && this.createAgentForSentEmail !== null;
            },
            validEmailAttachments() {
                return this.totalEmailAttachments > 0;
            },
            validNewEmailAttachmentFiles() {
                return this.newAttachmentFiles?.length > 0;
            },
        },
        components: {
            QuickReplyTiles,
            TiptapVuetify,
            TranslationPicker,
        },
        props: {
            accountId: Number,
            audioEmailOutcoming: HTMLAudioElement,
            cc: Array,
            createAgentForSentEmail: Function,
            currentCampaignCustomer: Object,
            customerId: Number,
            customerEmailAddressEmail: String,
            exit: Function,
            composingOptions: Object,
            emailThreads: Array,
            from: String,
            mainClientHeight: Number,
            otherCampaignCustomerId: Number,
            parentEmailId: Number,
            purpose: String,
            showSnackbar: Function,
            subject: String,
            to: Array,
        },
        destroyed() {
        },
        created() {
            this.initNewEmail();
        },
        mounted() {
        },
        watch: {
            "newEmail.attachments"() {
                this.buildAttachmentRows();
            },
        },
    });
</script>

<style lang="scss">
    #compose-email .tiptap-vuetify-editor__content {
        min-height: 300px;
    }

    .grey-background {
        background-color: grey;
    }
</style>
