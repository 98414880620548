<template lang="pug">
    v-dialog(
        v-model='dialog'
        max-width="600"
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on, attrs }')
            v-btn.white--text.mr-4(
                color="info"
                v-bind='attrs'
                v-on='on'
                dark
                small
            )
                v-icon mdi-phone-return
                | Saved Place In Line Call Details

        v-card
            v-toolbar
                v-btn(icon @click='closeDialog();')
                    v-icon mdi-close
                v-toolbar-title Saved Place In Line Call Details

            v-card-text
                template(v-if="validSavedPlaceInLineCall")
                    v-list(two-line)
                        template
                            v-list-item
                                v-list-item-content
                                    v-list-item-title {{ savedPlaceInLineCall.call_communication_queue.organization_name }}
                                    v-list-item-subtitle.text--primary(v-text='savedPlaceInLineCall.call_communication_queue.campaign_name')

                        template
                            v-divider(inset)
                            v-list-item
                                v-list-item-icon
                                    v-icon(color='primary')
                                        | mdi-phone
                                v-list-item-content
                                    v-list-item-title {{ savedPlaceInLineCall.call_communication_queue.communication_queue_name }}
                                    v-list-item-subtitle {{ savedPlaceInLineCall.call_communication_queue.language_name }}
                        
                        template
                            v-divider(inset)
                            v-list-item
                                v-list-item-icon
                                    v-icon(color='primary')
                                        | mdi-clock
                                v-list-item-content
                                    v-list-item-title Customer Has Been Waiting For Callback For: {{ waitDuration }} 
                                    v-list-item-subtitle Customer Wait Time

                        template
                            v-divider(inset)
                            v-list-item
                                v-list-item-icon
                                    v-icon(color='primary')
                                        | mdi-magnify
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="savedPlaceInLineCall.call_communication_queue.customer_match_code === 1") Perfect
                                        template(v-else-if="savedPlaceInLineCall.call_communication_queue.customer_match_code === 2") Multiple potential customers found
                                        template(v-else) No customer matches
                                    v-list-item-subtitle Match Status

                        template
                            v-divider(inset)
                            v-list-item
                                v-list-item-icon
                                    v-icon(color='primary')
                                        | mdi-account
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="savedPlaceInLineCall.call_communication_queue.customer_phone_number === '+266696687'") ANONYMOUS
                                        template(v-else) {{ savedPlaceInLineCall.call_communication_queue.customer_phone_number | tel }}
                                    v-list-item-subtitle Caller ID
                                    
                            v-list-item(v-if="validCustomerFullName")
                                v-list-item-icon
                                v-list-item-content
                                    v-list-item-title {{ savedPlaceInLineCall.call_communication_queue.customer_full_name }}
                                    v-list-item-subtitle Name

                        template(v-if="validCanceledMissedCall")
                            v-divider(inset)
                            v-list-item
                                v-list-item-icon
                                    v-icon(color='error')
                                        | mdi-alert
                                v-list-item-content
                                    v-list-item-title.error--text
                                        strong
                                            Timeago(
                                                :datetime="savedPlaceInLineCall.call_communication_queue.canceled_missed_call_created_at"
                                            )
                                    v-list-item-subtitle.error--text Previous Missed Call


                v-card-actions.text-right
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='closeDialog();')
                            v-icon mdi-window-close
                            | Close
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false;
            },
        },
        computed: {
            waitDuration() {
                if (!this.savedPlaceInLineCall.scheduled_communication_created_at) {
                    return "Unknown";
                }

                const startTime = new Date(this.savedPlaceInLineCall.scheduled_communication_created_at);
                const currentTime = new Date();

                // Calculate total elapsed time in milliseconds
                const diffMs = currentTime.getTime() - startTime.getTime();

                // Convert to minutes and seconds
                const totalSeconds = Math.floor(diffMs / 1000);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;

                // return formatted string
                return minutes + "m and " + seconds + "s";
            },
            validCustomerFullName() {
                return this.savedPlaceInLineCall?.call_communication_queue?.customer_id > 0 && this.savedPlaceInLineCall.call_communication_queue?.customer_full_name?.length > 0;
            },
            validCanceledMissedCall() {
                return this.savedPlaceInLineCall?.call_communication_queue?.canceled_missed_call_id > 0;
            },
            validSavedPlaceInLineCall() {
                return this.savedPlaceInLineCall?.call_communication_queue?.call_communication_queue_id > 0;
            },
        },
        components: {
        },
        props: {
            savedPlaceInLineCall: Object,
        },
        watch: {
        },
        created() {
        },
        destroyed() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
