<template lang="pug">
    v-card(outlined rounded elevation="2" v-bind:class="[isHighlight]" style="padding: 0 !important; margin-bottom: 25px;" v-if="validEmail")
        v-card-text
            v-list(three-line style="padding: 0;")
                v-list-item
                    v-list-item-icon(style="margin-top: 18px;")
                        v-tooltip(top :disabled="!isUnresponded")
                            template(v-slot:activator='{ on, attrs }')
                                span(v-bind="attrs" v-on="on" role="text")
                                    v-icon(:color="isUnresponded ? 'error' : null") mdi-account-circle
                            span
                            | Unresponded
                    v-list-item-content
                        v-list-item-title.font-weight-bold {{ displayFrom(email.from_address, email.from_name) }}
                        v-list-item-subtitle {{ email.subject }}
                        v-list-item-subtitle(v-if="displayToRecipients.length > 0") To: {{ displayToRecipients }}
                        v-list-item-subtitle(v-if="displayCcRecipients.length > 0") Cc: {{ displayCcRecipients }}
                    v-list-item-action
                        v-list-item-action-text(v-text='displayEmailTime')
                        v-btn-toggle.mt-2(dense rounded)
                            v-btn(icon @click="isViewingInfo = !isViewingInfo;")
                                v-icon(small) mdi-information
                            template(v-if="renderActionButtons")
                                v-btn(icon @click="startReplyToEmail(email.email_id, 'reply', email.subject, replyFrom, replyToRecipients, [], email.text_plain_body, email.direction);")
                                    v-icon(small) mdi-reply
                                v-btn(icon @click="startReplyToEmail(email.email_id, 'replyall', email.subject, replyFrom, replyToRecipients, chipCcRecipients, email.text_plain_body, email.direction);" v-if="totalEmailRecipients > 1")
                                    v-icon(small) mdi-reply-all
                                v-btn(icon @click="startReplyToEmail(email.email_id, 'forward', email.subject, replyFrom, [], [], email.text_plain_body, email.direction);")
                                    v-icon(small) mdi-share

                v-divider(
                    style="margin: 0;"
                )

            template(v-if="isViewingInfo")
                v-container
                    v-simple-table
                        template(v-slot:default)
                            tbody
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Email Key
                                        .cell-value-spacing(v-if="email.email_key && email.email_key.length > 0") {{ email.email_key }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 SID
                                        .cell-value-spacing(v-if="email.sid && email.sid.length > 0") {{ email.sid }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Direction
                                        .cell-value-spacing {{ email.direction | titleize }}
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 From Address
                                        .cell-value-spacing(v-if="email.from_address && email.from_address.length > 0") {{ email.from_address }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 From Name
                                        .cell-value-spacing(v-if="email.from_name && email.from_name.length > 0") {{ email.from_name }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Reply To Address
                                        .cell-value-spacing(v-if="email.reply_to_address && email.reply_to_address.length > 0") {{ email.reply_to_address }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Reply To Name
                                        .cell-value-spacing(v-if="email.reply_to_name && email.reply_to_name.length > 0") {{ email.reply_to_name }}
                                        .cell-value-spacing(v-else) N/a
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Error
                                        .cell-value-spacing(v-if="email.error_message && email.error_message.length > 0") Yes: {{ email.error_message }}
                                        .cell-value-spacing(v-else) N/a

                    v-divider
                    h6.font-weight-bold
                        template(v-if="totalEmailEvents === 1") {{ totalEmailEvents | number }} Event
                        template(v-else) {{ totalEmailEvents | number }} Events

                    v-simple-table.table-x-scroll(v-if="totalEmailEvents > 0" style="width: 100%;")
                        template(v-slot:default)
                            thead
                                tr
                                    th Event
                                    th Timestamp
                                    th Recipient
                            tbody
                                tr(v-for='emailEvent in email.email_events' :key='emailEvent.vid')
                                    td.no-wrap.sm-width
                                        EmailEvent(:name="emailEvent.friendly_event")
                                    td.no-wrap.sm-width
                                        template(v-if="emailEvent.friendly_timestamp && emailEvent.friendly_timestamp.length > 0") {{ emailEvent.friendly_timestamp }}
                                        template(v-else) N/a
                                    td.no-wrap.sm-width
                                        template(v-if="emailEvent.friendly_email_recipient_name && emailEvent.friendly_email_recipient_name.length > 0") {{ emailEvent.friendly_email_recipient_name }}
                                        template(v-else) N/a

            template(v-else)
                v-container(v-html="email.text_html_body")

            template(v-if="validAttachments")
                v-divider(
                    style="margin: 0;"
                )

                v-container
                    h6.font-weight-bold
                        template(v-if="totalEmailAttachments === 1") {{ totalEmailAttachments | number }} Attachment
                        template(v-else) {{ totalEmailAttachments | number }} Attachments

                    v-row(
                        v-if="validAttachmentRows"
                        v-for='(row, index) in attachmentRows'
                        :key='index'
                    )
                        v-col(
                            cols="4"
                            v-for='attachment in row'
                            :key='attachment.email_attachment_key'
                        )
                            ViewingEmailAttachment(
                                :email-attachment="attachment"
                            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailEvent from "@/components/emails/email_event.vue";
    import ViewingEmailAttachment from '@/components/communication_center/email/viewing_email_attachment.vue';

    // Data
    import {
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                attachmentRows: [],
                isViewingInfo: false,
            }
        },
        methods: {
            buildAttachmentRows() {

                // Ensure we have attachments
                if (!this.validAttachments) {
                    return;
                }

                const MAX_PER_ROW = 3;

                // Loop through attachments and fill in rows
                for (let i = 0; i < this.email.email_attachments.length; i++) {

                    // Init
                    const attachment = this.email.email_attachments[i];

                    // If the last row has less attachments than the max per row, push onto it
                    // Otherwise start a new row
                    const lastRow = this.attachmentRows[this.attachmentRows.length -1];
                    if (lastRow?.length < MAX_PER_ROW) {
                        lastRow.push(attachment);
                    } else {
                        this.attachmentRows.push([attachment]);
                    }
                }
            },
            displayFrom(fromEmail, fromName) {
                return fromName?.length > 0 ? fromName + " <" + fromEmail + ">" : fromEmail;
            },
        },
        computed: {
            ccRecipients() {

                // Init
                const recipients = [];
                if (!(this.email?.email_recipients?.length > 0)) {
                    return recipients;
                }

                // Loop
                for(let i = 0; i < this.email?.email_recipients?.length; i++) {

                    // Init
                    const recipient = this.email?.email_recipients[i];
                    if (!recipient) {
                        continue;
                    }

                    // Check for cc
                    if (recipient.recipient_type !== "cc") {
                        continue;
                    }

                    recipients.push(recipient);
                }

                return recipients;
            },
            chipCcRecipients() {
                return this.ccRecipients.map(x => (x.to_name?.length > 0 ? x.to_name + ' <' + x.to_address + '>' : x.to_address));
            },
            chipToRecipients() {
                return this.toRecipients.map(x => (x.to_name?.length > 0 ? x.to_name + ' <' + x.to_address + '>' : x.to_address));
            },
            displayCcRecipients() {
                return this.chipCcRecipients.join(", ");
            },
            displayEmailTime() {

                // Verify
                if (!this.email || !(this.email?.created_at?.length > 0)) {
                    return "";
                }

                // Load date
                const d = new Date(this.email?.created_at);
                if (!d) {
                    return null;
                }

                // Build friendly date
                const friendlyDate = d.toLocaleDateString('en-US', {day: '2-digit', month:'2-digit', year:'numeric'});

                // If today's date, render time. Otherwise render local date
                return friendlyDate === this.todaysDate ? 'Today, ' + d.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}) : d.toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', day: '2-digit', month:'2-digit', year:'numeric'});
            },
            displayToRecipients() {
                return this.chipToRecipients.join(", ");
            },
            isHighlight() {
                return this.highlightEmailId > 0 && this.highlightEmailId === this.email?.email_id ? 'border-highlight' : null;
            },
            isUnresponded() {
                return this.email?.unresponded_communication_response_id > 0;
            },
            renderActionButtons() {
                return !this.isViewOnly;
            },
            replyToRecipients() {

                // Init
                const tos = [];
                if (this.chipToRecipients?.length > 0) {

                    // Init
                    let matchedReplyFrom = false;
                    
                    // Loop through and see if to is the same as the reply from, if not, append
                    for (let i = 0; i < this.chipToRecipients.length; i++) {

                        // Init
                        const recipient = this.chipToRecipients[i];
                        if (!recipient) {
                            continue;
                        }

                        // Check if it matches reply from
                        if (recipient === this.replyFrom) {
                            matchedReplyFrom = true;
                            continue;
                        }

                        // Append
                        tos.push(recipient);
                    }

                    // If matched, append from into tos
                    if (matchedReplyFrom) {
                        tos.push(this.displayFrom(this.email.from_address, this.email.from_name));
                    }
                }

                return tos;
            },
            toRecipients() {

                // Init
                const recipients = [];
                if (!(this.email?.email_recipients?.length > 0)) {
                    return recipients;
                }

                // Loop
                for(let i = 0; i < this.email?.email_recipients?.length; i++) {

                    // Init
                    const recipient = this.email?.email_recipients[i];
                    if (!recipient) {
                        continue;
                    }

                    // Check for to
                    if (recipient.recipient_type !== "to") {
                        continue;
                    }

                    recipients.push(recipient);
                }

                return recipients;
            },
            totalEmailAttachments() {
                return (this.email?.email_attachments?.length || 0);
            },
            totalEmailEvents() {
                return (this.email?.email_events?.length || 0);
            },
            totalEmailRecipients() {
                return (this.email?.email_recipients?.length || 0);
            },
            validAttachments() {
                return this.totalEmailAttachments > 0;
            },
            validAttachmentRows() {
                return this.attachmentRows?.length > 0;
            },
            validEmail() {
                return this.email?.email_id > 0;
            },
            validEmailEvents() {
                return this.totalEmailEvents > 0;
            },
        },
        components: {
            EmailEvent,
            ViewingEmailAttachment,
        },
        props: {
            email: Object,
            highlightEmailId: Number,
            isViewOnly: Boolean,
            replyFrom: String,
            startReplyToEmail: Function,
        },
        destroyed() {
        },
        created() {
            this.buildAttachmentRows();
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .border-highlight {
            border: 2px solid #0f5b9a;
        }
    }
</style>
