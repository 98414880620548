import { get, post, put, destroy } from '.';

export function CreateBadName(options = {}) {
    return post<any>("/app/api/bad_names", options);
}

export function DeleteBadName(id: string, options = {}) {
    return destroy<any>(`/app/api/bad_names/${id}`, options);
}

export function GetBadNames(options = {}) {
    return get<any>(`/app/api/bad_names`, options);
}


export function UpdateBadName(id: string, options = {}) {
    return put<any>(`/app/api/bad_names/${id}`, options);
}

