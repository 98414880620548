<template lang="pug">
    v-row
        v-col
            v-chip(@click="quickReply('visit');" color="grey" text-color="white")
                | Visit Dealer
        v-col
            v-chip(@click="quickReply('no_stock');" color="grey" text-color="white")
                | No Stock
        v-col
            v-chip(@click="quickReply('in_stock');" color="grey" text-color="white")
                | In Stock
</template>

<script lang="ts">
import Vue from 'vue';

// Components

export default Vue.extend({
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {

    },
    components: {

    },
    props: {
        quickReply: Function,
    },
    watch: {

    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>