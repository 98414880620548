<template lang="pug">
    v-dialog(
        v-model="dialog"
        persistent
        v-if="validDialog"
        :max-width="dialogMaxWidth"
    )
        v-card.mx-auto
            v-toolbar(dark color='white')
                v-btn(
                    icon
                    light
                    @click='exit();'
                    v-if="!bridgeCalls"
                )
                    v-icon mdi-close
                v-toolbar-title.text--primary {{ location | titleize }} {{ type | titleize }} Transfer
            v-card-text(
                v-if="externalLocation"
            )
                v-alert.mt-md-4(
                    v-if="bridgeCalls && holdingParty === 'customer'"
                    outlined
                    type="success"
                )
                    | Please bridge the call when you are ready.

                v-alert.mt-md-4(
                    v-if="bridgeCalls && holdingParty === 'external'"
                    outlined
                    type="error"
                )
                    | Currently speaking with customer.

                DialPad(
                    v-if="bridgeCalls"
                    :call-id="callId"
                    :send-digit="sendDigit"
                    is-transfer=true
                )

                v-form
                    v-row(align="center")
                        v-col(cols='12')
                            v-select(
                                :items='countries'
                                :item-text="countryDisplay"
                                item-value="iso_two"
                                label='Country'
                                v-model="countryIsoTwo"
                            )
                    v-row(align="center")
                        v-col(cols='12')
                            v-text-field(
                                label='Phone Number'
                                type='text'
                                v-model="phoneNumber"
                                v-on:keypress="phoneNumberChange"
                            )

                    v-row(align="center")
                        v-col(cols='12')
                            .text-right
                                template(v-if="warmExternal")
                                    v-tooltip(bottom)
                                        template(v-slot:activator='{ on, attrs }')
                                            v-btn.mr-4(v-bind='attrs' v-on='on' @click="muteUnmuteCall();" :color="muteBtnColor")
                                                v-icon(dark) mdi-microphone-off
                                        span(v-if="!isMuted") Mute
                                        span(v-else) Unmute
                                v-btn.mr-4(
                                    v-if="!bridgeCalls"
                                    @click='exit();'
                                )
                                    v-icon mdi-close
                                    | Cancel
                                v-btn.mr-4(
                                    v-else
                                    color="error"
                                    @click='cancelCallTransfer();'
                                )
                                    v-icon mdi-arrow-left-bold
                                    | Cancel Transfer

                                v-btn.mr-4(
                                    v-if="bridgeCalls && swapAvailable"
                                    color='warning'
                                    @click='swapHoldForCallTransfer();'
                                )
                                    template(
                                        v-if='holdingParty === "customer"'
                                    )
                                        v-icon mdi-phone-incoming
                                        | Speak with Customer
                                    template(
                                        v-else
                                    )
                                        v-icon mdi-phone-outgoing
                                        | Speak with Transfer

                                v-btn.mr-4(
                                    v-if="!bridgeCalls"
                                    color='success'
                                    @click='createCallTransfer();'
                                    :disabled="!validPhoneNumber || isLoading"
                                )
                                    v-icon mdi-phone-forward
                                    | Transfer
                                v-btn.mr-4(
                                    v-else
                                    color='success'
                                    @click='bridgeCallTransfer();'
                                    :disabled="componentDisableBridgeCallsButton"
                                )
                                    v-icon mdi-arrow-right-bold
                                    | Bridge Call

            v-card-text(
                v-else-if="internalLocation"
                style="height: 600px; overflow-y: auto;"
            )
                .text-center(v-if="isLoading")
                    v-progress-circular(
                        :size='100'
                        :width='10'
                        color='#0f5b9a'
                        indeterminate
                        v-bind:style="{ marginTop: '25px', marginBottom: internalTransferDialogFooterHeight + 'px' }"
                    )

                template(v-else)
                    v-simple-table.my-md-4
                        template(v-slot:default)
                            thead
                                tr
                                    th(style="width: 75%; max-width: 75%;" v-if="teamDestination")
                                        v-text-field(
                                            label='Name'
                                            v-model="dialogSearch.teamName"
                                            clearable
                                            ref="name"
                                        )

                                    th(style="width: 25%; max-width: 25%;" v-if="userDestination")
                                        v-text-field(
                                            label='Name'
                                            v-model="dialogSearch.userName"
                                            clearable
                                            ref="name"
                                        )

                                    th(style="width: 25%; max-width: 25%;" v-if="userDestination")
                                        v-text-field(
                                            label='Email'
                                            v-model="dialogSearch.userEmail"
                                            clearable
                                            ref="email"
                                        )

                                    th(style="width: 25%; max-width: 25%;" v-if="userDestination")
                                        v-text-field(
                                            label='Role'
                                            v-model="dialogSearch.userRole"
                                            clearable
                                            ref="role"
                                        )

                                    th(style="width: 25%; max-width: 25%;")
                                        v-select(
                                            v-model='transferDestination'
                                            :items='internalCallTransferDestinations'
                                            label='Destination'
                                            item-text="key"
                                            item-value="value"
                                            v-on:change="transferDestinationChange();"
                                        )

                    template(v-if="teamDestination")
                        v-simple-table.table-y-scroll(
                            v-if="validAvailableCallTransferTeams"
                            v-bind:style="{ marginBottom: internalTransferDialogFooterHeight + 'px' }"
                        )
                            template(v-slot:default)
                                thead
                                    tr
                                        th(style="width: 90%; max-width: 90%;") Name
                                        th(style="width: 10%; max-width: 10%;") Select
                                tbody
                                    tr(v-for='availableTeam in renderTeams' :key='availableTeam.team_id')
                                        td.no-wrap.sm-width
                                            template
                                                | {{ availableTeam.team_name }}
                                                br
                                                | {{ availableTeam.is_account_team }}
                                        td.no-wrap.sm-width
                                            v-checkbox(v-model='availableTeam.enabled' v-on:change="addOrRemoveDestinationId(availableTeam.team_id, availableTeam.enabled);")

                        v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                            | Unable to find any teams.

                    template(v-if="userDestination")
                        v-simple-table.table-y-scroll(
                            v-if="validAvailableCallTransferUsers"
                            v-bind:style="{ marginBottom: internalTransferDialogFooterHeight + 'px' }"
                        )
                            template(v-slot:default)
                                thead
                                    tr
                                        th Name
                                        th Role
                                        th Status
                                        th Time
                                        th Select
                                tbody
                                    tr(v-for='availableUser in renderUsers' :key='availableUser.user_id')
                                        td.no-wrap.sm-width
                                            template
                                                | {{ availableUser.user_full_name }}
                                                br
                                                | {{ availableUser.user_email }}
                                        td.no-wrap.sm-width {{ availableUser.role_name }}
                                        td.no-wrap.sm-width
                                            template(v-if="availableUser.is_online") {{ availableUser.agent_status_name }}
                                            .error--text(v-else) Offline
                                        td.no-wrap.sm-width
                                            template(v-if="availableUser.is_online")
                                                Timeago(
                                                    :datetime="availableUser.agent_timestamp"
                                                    :auto-update="30"
                                                )
                                            template(v-else) ---
                                        td.no-wrap.sm-width
                                            v-checkbox(v-model='availableUser.enabled' v-on:change="addOrRemoveDestinationId(availableUser.user_id, availableUser.enabled);")

                        v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                            | Unable to find any users.

            v-footer.font-weight-medium.no-padding#internalTransferDialogFooter(absolute v-if="internalLocation")
                v-col.text-center.no-padding(cols='12')
                    v-container.no-padding-bottom(fluid)
                        v-textarea(
                            v-model="notes"
                            label='Type notes'
                            rows="1"
                            auto-grow
                            clearable
                            counter
                            filled
                            outlined
                            dense
                            @click:clear="setInternalTransferDialogFooterHeight();"
                        )

                v-row
                    v-col.text-center.no-padding(cols='6')
                        v-btn(color="#0f5b9a" outlined ripple @click='exit();' style="margin-bottom: 26px !important;")
                            v-icon mdi-window-close
                            | Close
                    v-col.text-center.no-padding(cols='6')
                        v-btn(
                            color="success"
                            outlined
                            ripple
                            @click='createCallTransfer();'
                            :disabled="!validDestinationIDs || isLoading"
                            style="margin-bottom: 26px !important;"
                        )
                            v-icon mdi-phone-forward
                            | Transfer

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import DialPad from '@/components/communication_center/phone/dial_pad_card.vue';

    // Data
    import { BridgeCallTransfer, CancelCallTransfer, CreateCallTransfer, GetAvailableCallTransferTeams, GetAvailableCallTransferUsers,SwapCallTransferHoldStatus } from "@/data";

    // Helpers
    import { cleanupPhoneNumber } from "@/helpers/phone_number";

    export default Vue.extend({
        data() {
            return {
                availableCallTransferTeams: [],
                availableCallTransferUsers: [],
                bridgeCalls: false,
                callTransferID: null,
                countryIsoTwo: "US",
                destinationIDs: [],
                dialog: true,
                dialogMaxWidth: "800px",
                dialogSearch: {},
                componentDisableBridgeCallsButton: this.disableBridgeCallsButton,
                holdingParty: "customer",
                internalCallTransferDestinations: [
                    { key: "Teams", value: "team" },
                    { key: "Users", value: "user" },
                ],
                internalTransferDialogFooterHeight: null,
                isBridgingCallTransfer: false,
                isCancelingCallTransfer: false,
                isLoading: false,
                notes: null,
                phoneNumber: null,
                renderTeams: [],
                renderUsers: [],
                transferDestination: null,
            }
        },
        methods: {
            addOrRemoveDestinationId(destinationID, enabled) {

                // Verify
                if (!destinationID) {
                    return;
                }

                // If enabled, add to array. Otherwise remove from array
                if (enabled) {
                    this.destinationIDs.push(destinationID);
                } else {

                    // Find index
                    const idx = this.destinationIDs.indexOf(destinationID);
                    if (idx >= 0) {
                        this.destinationIDs.splice(idx, 1);
                    }
                }
            },
            bridgeCallTransfer() {

                // Verify so we don't run request multiple times
                if (!this.callTransferID || this.isBridgingCallTransfer === true) {
                    return;
                }

                // unmute automatically (will be unmuted in bridging on backend - this will make the UI match reality)
                if (this.isMuted) {
                    this.muteUnmuteCall();
                }

                this.isBridgingCallTransfer = true;
                BridgeCallTransfer(this.callTransferID).then((response) => {
                    if (response?.Data?.Ok) {
                        this.exit();

                        // todo - notify agent of success
                    }
                });
            },
            buildRenderTeams() {

                // Reset
                this.renderTeams = [];

                // If we don't have valid teams, abort
                if (!this.validAvailableCallTransferTeams) {
                    return;
                }

                // Loop through and filter
                for (let i = 0; i < this.availableCallTransferTeams.length; i++) {

                    // Init
                    const team = this.availableCallTransferTeams[i];
                    if (!team || !(team.team_id > 0)) {
                        continue;
                    }

                    // Name filter
                    if (this.dialogSearch.teamName?.length > 0) {

                        // Init
                        const filter = this.dialogSearch.teamName.toLowerCase().trim();
                        const teamName = (team.team_name || "").toLowerCase().trim();

                        // If team's name doesn't include filter, skip them (so they won't show up in render)
                        if (!teamName.includes(filter)) {
                            continue;
                        }
                    }

                    // If we made it this far, push onto render array
                    this.renderTeams.push(team);
                }
            },
            buildRenderUsers() {

                // Reset
                this.renderUsers = [];

                // If we don't have valid users, abort
                if (!this.validAvailableCallTransferUsers) {
                    return;
                }

                // Loop through and filter
                for (let i = 0; i < this.availableCallTransferUsers.length; i++) {

                    // Init
                    const user = this.availableCallTransferUsers[i];
                    if (!user || !(user.user_id > 0)) {
                        continue;
                    }

                    // Name filter
                    if (this.dialogSearch.userName?.length > 0) {

                        // Init
                        const filter = this.dialogSearch.userName.toLowerCase().trim();
                        const userName = (user.user_full_name || "").toLowerCase().trim();

                        // If user's name doesn't include filter, skip them (so they won't show up in render)
                        if (!userName.includes(filter)) {
                            continue;
                        }
                    }

                    // Email filter
                    if (this.dialogSearch.userEmail?.length > 0) {

                        // Init
                        const filter = this.dialogSearch.userEmail.toLowerCase().trim();
                        const userEmail = (user.user_email || "").toLowerCase().trim();

                        // If user's email doesn't include filter, skip them (so they won't show up in render)
                        if (!userEmail.includes(filter)) {
                            continue;
                        }
                    }

                    // Role filter
                    if (this.dialogSearch.userRole?.length > 0) {

                        // Init
                        const filter = this.dialogSearch.userRole.toLowerCase().trim();
                        const userRole = (user.role_name || "").toLowerCase().trim();

                        // If user's role doesn't include filter, skip them (so they won't show up in render)
                        if (!userRole.includes(filter)) {
                            continue;
                        }
                    }

                    // If we made it this far, push onto render array
                    this.renderUsers.push(user);
                }
            },
            cancelCallTransfer() {

                // Verify so we don't run request multiple times
                if (!this.callTransferID || this.isCancelingCallTransfer === true) {
                    return;
                }

                this.isCancelingCallTransfer = true;
                CancelCallTransfer(this.callTransferID).then((response) => {
                    if (response?.Data?.Ok) {
                        this.exit();
                        // todo - notify agent of success
                    }
                });
            },
            createCallTransfer() {

                const options = {
                    call_id: this.callId,
                    destination_id: (this.destinationId || 0),
                    destination_ids: (this.destinationIDs || []),
                    notes: this.notes,
                    transfer_destination: this.transferDestination,
                    transfer_location: this.location,
                    transfer_to_number: this.cleanPhoneNumber,
                    transfer_type: this.type,
                }

                this.isLoading = true;
                CreateCallTransfer(options).then((response) => {
                    if (response.Data?.call_transfer?.id > 0) {

                        // Set
                        this.callTransferID = response.Data?.call_transfer?.id;

                        // After success
                        if (this.type === "blind") {

                            // End the call
                            this.endCall();

                            // If internal transfer, need to move on to the next record
                            if (this.location === "internal") {
                                this.completeAttemptForInternalTransfer();
                            }

                            // Close dialog
                            this.exit();
                        } else if (this.type === "warm") {
                            this.bridgeCalls = true;
                        }
                    }
                    this.isLoading = false;
                });
            },
            countryDisplay(item) {
                return item?.name + ' (' + item?.dialing_code + ")";
            },
            exit() {
                this.dialog = false;
                this.exitTransferDialog();
            },
            getAvailableCallTransferTeams() {

                // Verify
                if (!this.validDialog) {
                    return;
                }

                // Init
                const options = {
                }

                this.isLoading = true;
                GetAvailableCallTransferTeams(this.callId, options).then((response) => {

                    // Set call transfer users
                    if (response.Data?.available_call_transfer_teams?.length > 0) {
                        this.availableCallTransferTeams = response.Data.available_call_transfer_teams;
                    } else {
                        this.availableCallTransferTeams = [];
                    }

                    this.isLoading = false;
                });
            },
            getAvailableCallTransferUsers() {

                // Verify
                if (!this.validDialog) {
                    return;
                }

                // Init
                const options = {
                }

                this.isLoading = true;
                GetAvailableCallTransferUsers(this.callId, options).then((response) => {

                    // Set call transfer users
                    if (response.Data?.available_call_transfer_users?.length > 0) {
                        this.availableCallTransferUsers = response.Data.available_call_transfer_users;
                    } else {
                        this.availableCallTransferUsers = [];
                    }

                    this.isLoading = false;
                });
            },
            initDialogSearch() {
                this.dialogSearch = {
                    teamName: null,
                    userEmail: null,
                    userName: null,
                    userRole: null,
                }
            },
            phoneNumberChange(e) {

                // Ensure only numbers are entered
                if (isNaN(e.key)) {
                    e.preventDefault();
                }
            },
            setInternalTransferDialogFooterHeight() {

                // Find element
                const elem = document.getElementById("internalTransferDialogFooter");
                if (!elem || !(elem?.clientHeight > 0)) {
                    this.internalTransferDialogFooterHeight = 140;
                } else {
                    this.internalTransferDialogFooterHeight = elem.clientHeight;
                }
            },
            swapHoldForCallTransfer() {
                const options = {
                    holding_party: this.holdingParty,
                }

                SwapCallTransferHoldStatus(this.callTransferID, options).then((response) => {
                    if (response?.Data?.Ok) {
                        this.holdingParty = response.Data.holding_party;
                    } else {
                        // transfer leg has left the call (if customer leaves conference, conference is ended)
                        this.cancelCallTransfer();
                    }
                });
            },
            transferDestinationChange() {

                // Reinit search
                this.initDialogSearch();

                // Reinit destinationIDs
                this.destinationIDs = [];

                return this.transferDestination === "team" ? this.getAvailableCallTransferTeams() : this.getAvailableCallTransferUsers();
            },
        },
        computed: {
            cleanPhoneNumber() {
                if (this.phoneNumber?.length > 0) {

                    // Validate
                    const cleaned = cleanupPhoneNumber(this.phoneNumber, this.countryIsoTwo);
                    if (cleaned?.valid === true) {
                        return cleaned.phoneNumber;
                    }
                }

                return "";
            },
            countries() {
                return VolieState.countries();
            },
            warmExternal() {
                return this.location === "external" && this.type === "warm";
            },
            externalLocation() {
                return this.location === "external";
            },
            internalLocation() {
                return this.location === "internal";
            },
            swapAvailable() {
                return this.communicationProvider === "telnyx" || this.communicationProvider === "twilio"
            },
            teamDestination() {
                return this.transferDestination === "team";
            },
            userDestination() {
                return this.transferDestination === "user";
            },
            validAvailableCallTransferTeams() {
                return this.availableCallTransferTeams?.length > 0;
            },
            validAvailableCallTransferUsers() {
                return this.availableCallTransferUsers?.length > 0;
            },
            validDestination() {
                return ["department", "employee", "misc", "team", "unknown", "user"].indexOf(this.destination) >= 0;
            },
            validDestinationIDs() {
                return this.destinationIDs?.length > 0;
            },
            validDialog() {
                return this.callId > 0 && this.validDestination && this.validLocation && this.validType;
            },
            validLocation() {
                return ["external", "internal"].indexOf(this.location) >= 0;
            },
            validPhoneNumber() {
                return this.cleanPhoneNumber?.length > 0;
            },
            validType() {
                return ["blind", "warm"].indexOf(this.type) >= 0;
            },
        },
        components: {
            DialPad,
        },
        props: {
            callId: Number,
            communicationProvider: String,
            completeAttemptForInternalTransfer: Function,
            destination: String,
            destinationId: Number,
            disableBridgeCallsButton: Boolean,
            isMuted: Boolean,
            endCall: Function,
            exitTransferDialog: Function,
            location: String,
            muteUnmuteCall: Function,
            muteBtnColor: String,
            sendDigit: Function,
            setTransferBridgeCalls: Function,
            transferToNumber: String,
            type: String,
        },
        created() {

            // Set on component since for internal this can change
            this.transferDestination = this.destination;

            // Override if present
            if (this.transferToNumber?.length > 0) {
                this.phoneNumber = this.transferToNumber;
                // todo - cleanup transfer number and if valid, set phone number to the phone number minus the country code
            }

            // Determine if we need to load available users
            if (this.location === "internal") {

                // Set dialog dimensions
                this.dialogMaxWidth = "1000px";
                this.setInternalTransferDialogFooterHeight();
                this.initDialogSearch();

                // Load
                this.getAvailableCallTransferUsers();
            }
        },
        watch: {
            availableCallTransferTeams() {
                this.buildRenderTeams();
            },
            availableCallTransferUsers() {
                this.buildRenderUsers();
            },
            bridgeCalls() {
                if (this.setTransferBridgeCalls !== undefined && this.setTransferBridgeCalls !== null) {
                    this.setTransferBridgeCalls(this.bridgeCalls);
                }
            },
            holdingParty() {
                if (this.holdingParty == 'external') {
                    this.componentDisableBridgeCallsButton = true;
                } else {
                    this.componentDisableBridgeCallsButton = false;
                }
            },
            notes() {
                Vue.nextTick(() => { this.setInternalTransferDialogFooterHeight() });
            },
            dialogSearch: {
                deep: true,
                handler() {
                    return this.transferDestination === "team" ? this.buildRenderTeams() : this.buildRenderUsers();
                },
            },
        },
    });
</script>

<style lang="scss" scoped>
</style>
