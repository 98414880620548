<template lang="pug">
    v-card.rounded-lg(outlined tile style="padding: 0 !important;")
        v-toolbar.volie-v-toolbar-color(dark height="48px" style="margin-bottom: 10px;")
            v-btn.mr-sm-2(color="#FFFFFF" light small v-if="validExit" @click="exit();")
                | Exit
                v-icon
                    | mdi-close

            MessageTemplateMenu(
                :message-templates="messageTemplates"
                :apply-message-template="applyMessageTemplate"
                v-if="renderMessageTemplateMenu"
            )

            TagMenu(
                :menu-tags="menuTags"
                :apply-message-template="applyMessageTemplate"
                v-if="renderTagMenu"
            )
            v-spacer
            div(v-if="validCustomerConversationGroupPersonPhoneNumber") {{ conversationGroup.customer_conversation_group_person_phone_number | tel }}

        v-list(
            v-if="!!conversationGroup && conversationGroup.conversation_group_id > 0"
            style="overflow-y: auto;"
            v-bind:style="{ height: conversationGroupHeight + 'px' }"
            ref="conversationGroupContainer"
            id="conversationGroupContainer"
            v-scroll.self="onScrollConversationMessages"
        )

            .text-center(v-if="isLoadingConversations")
                v-progress-circular(
                    :size='25'
                    :width='2'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 15px; margin-bottom: 15px;"
                )

            v-list-item(
                v-if="validConversations"
                v-for='(conversation, i) in conversationGroup.conversations'
                :key='i'
                style="padding: 0px;"
            )
                ConversationMessages(
                    :conversation="conversation"
                )

            v-alert.ma-md-4(v-if="!validConversations" dense type='info') Send a message to start a conversation

        .text-center(v-else-if="isLoadingConversations")
            v-progress-circular(
                :size='25'
                :width='2'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 15px; margin-bottom: 15px;"
            )

        v-footer.font-weight-medium.no-padding(absolute)
            v-col.text-center.no-padding(cols='12' ref="smsMessageSoftphoneFooterCol")
                v-container.px-0.pt-3.pb-2(fluid)
                    AttachmentSlideGroup(
                        v-if="validAssets || validTmpAssets"
                        :assets="assets"
                        :tmp-assets="tmpAssets"
                        :handle-remove="removeAsset"
                    )

                    v-textarea(
                        v-model="newMessageBody"
                        label='Type message body'
                        rows="1"
                        auto-grow
                        clearable
                        counter
                        filled
                        outlined
                        dense
                        v-on:keydown="handleMessageBodyInputKeydown"
                        ref="newMessageTextarea"
                        class="px-3"
                        :error-messages="textAreaErrorMessages"
                        @blur="onBlurNewMessageBody"
                    )

                        template(v-slot:append)
                            v-btn.mb-2(icon small color="primary" @click="createMessage" :is-loading="isSending" :disabled="!eligibleToSend")
                                v-icon mdi-arrow-up-circle

                        template(v-slot:prepend)
                            v-file-input(
                                v-if="isMmsEnabled"
                                :key="fileUploaderKey"
                                hide-input
                                multiple
                                accept="application/pdf, audio/mp3, audio/mpeg, audio/wav, image/gif, image/jpeg, image/jpg, image/png, text/csv, text/rtf, video/mp4"
                                @change="onMediaFileUpload"
                                :error-messages="textAreaErrorMessages"
                                style="margin: 0px; margin-top: -8px; padding: 0px;"
                            )

                            v-btn(@click="displayEmojiPicker = !displayEmojiPicker" icon style="margin: 0px; margin-top: -5px; padding: 0px;")
                                v-icon mdi-emoticon-outline

                            //- v-btn(@click="displayTranslationPicker = !displayTranslationPicker" icon style="margin: 0px; margin-top: -5px; padding: 0px;")
                            //-     v-icon mdi-translate
                    
                    EmojiPicker(
                        v-if="displayEmojiPicker"
                        :select-emoji="selectEmoji"
                    )

                    //- TranslationPicker(
                    //-     v-if="displayTranslationPicker"
                    //-     :translate-message="translateMessage"
                    //- )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import Attachment from '@/components/shared/attachments/attachment_card.vue';
    import AttachmentSlideGroup from '@/components/shared/attachments/attachment_slide_group.vue';
    import ConversationMessages from '@/components/communication_center/sms_message/conversation_messages.vue';
    import EmojiPicker from '@/components/communication_center/sms_message/emoji_picker.vue';
    import MessageTemplateMenu from '@/components/communication_center/sms_message/message_template_menu.vue';
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";
    import TagMenu from '@/components/communication_center/sms_message/tag_menu.vue';
    import TranslationPicker from '@/components/communication_center/sms_message/translation_picker.vue';

    // Data
    import { CreateAssets, CreateMessage, CreateTranslation, GetCommunicationCenterSmsMessageSoftphone, GetConversationGroupConversations, ReadAllConversationGroupUnreadMessages } from "@/data";

    export default Vue.extend({
        data() {
            return {
                assets: [],
                attachments: [],
                bus: VolieState.websocketClient().eventBus(),
                conversationGroup: {},
                conversationGroupHeight: null,
                displayEmojiPicker: false,
                displayTranslationPicker: false,
                fileUploaderKey: null,
                isLoadingConversations: false,
                isSending: false,
                lastConversationID: 0,
                menuTags: [],
                messageTemplates: [],
                messageTemplateTags: [],
                newMessageBody: null,
                newMessageTextareaCursorStartPosition: 0,
                newMessageTextareaCursorEndPosition: 0,
                readyToLazyLoadPreviousConversations: false,
                tagMenu: false,
                tmpAssets: [],
            }
        },
        methods: {
            applyMessageTemplate(messageTemplate) {
                this.tagMenu = false;
                this.newMessageBody = messageTemplate?.message_template_body;
                this.makeNewMessageTextareaFocused();
            },
            buildTagMenu() {

                // If either are empty, abort
                if (!(this.messageTemplates?.length > 0 && this.messageTemplateTags?.length > 0)) {
                    return;
                }

                const notTagged = {
                    account_id: 0,
                    tag_id: 0,
                    messageTemplates: [],
                    name: "Other",
                }
                const tagIDs = [];
                const tagMap = {};
                this.menuTags = [];
                for (let i = 0; i < this.messageTemplates.length; i++) {

                    // Init message template
                    const messageTemplate = this.messageTemplates[i];

                    // Init bool to represent whether or not the template was matched
                    let matchedTag = false;

                    // Loop through all resource tags to match templates
                    for (let j = 0; j < this.messageTemplateTags.length; j++) {

                        // Init resource tag
                        const messageTemplateTag = this.messageTemplateTags[j];

                        // Check if they match - if not, continue on
                        if (messageTemplateTag?.message_template_id !== messageTemplate?.message_template_id) {
                            continue
                        }

                        if (!!tagMap[messageTemplateTag.tag_id]) {
                            tagMap[messageTemplateTag.tag_id].messageTemplates.push(messageTemplate);                            
                        } else {

                            // Init tag
                            const tag = {
                                account_id: messageTemplateTag.account_id,
                                messageTemplates: [messageTemplate],
                                name: messageTemplateTag.tag_name,
                                tag_id: messageTemplateTag.tag_id,
                            }

                            // Set on map
                            tagMap[tag.tag_id] = tag;

                            // Push into ID array so we know which tags to grab later
                            tagIDs.push(tag.tag_id);
                        }

                        // Mark matched as true
                        matchedTag = true;
                    }

                    // If not tagged, push into other bucket
                    if (!matchedTag) {
                        notTagged.messageTemplates.push(messageTemplate);
                    }
                }

                // Build menu tags from map
                if (tagIDs?.length > 0) {
                    for (let i = 0; i < tagIDs.length; i++) {
                        const id = tagIDs[i];
                        if (id > 0) {
                            const tag = tagMap[id];
                            if (tag) {
                                this.menuTags.push(tag);
                            }
                        }
                    }
                }
                
                // If we have not tagged templates, append onto menu
                if (notTagged?.messageTemplates?.length > 0) {
                    this.menuTags.push(notTagged);
                }

                // Sort tags
                this.sortMenuTags();

                return;
            },
            createAgentForSentMessage(message) {
                if (!(message?.id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: message.campaign_customer_id,
                    campaign_id: message.campaign_id,
                    noticeable_id: message.id,
                    noticeable_type: "message",
                    organization_id: message.organization_id,
                    status_name: "SMS Message - Sent Message",
                }

                this.createAgent(options);
            },
            createAsset() {
                if (!(this.attachments?.length > 0)) {
                    return;
                }

                // Init formData
                const formData = new FormData();

                // Set common fields
                formData.set("account_id", (this.accountId || this.currentCampaignCustomer?.account_id || 0));
                formData.set("asset_id", (this.customerId || this.currentCampaignCustomer?.customer_id || 0));
                formData.set("asset_type", "customer");

                this.attachments.forEach((attachment, idx) => {
                    const fileNum = idx + 1
                    formData.append("file-" + fileNum.toString(), attachment);
                })

                // Upload assets
                CreateAssets(formData).then((response) => {
                    if (response.Data?.assets?.length > 0) {

                        // Loop through assets and add urls to sms attachments
                        for(let i = 0; i < response.Data.assets.length; i++) {

                            // Init
                            const asset = response.Data.assets[i];
                            if (!(asset?.asset_url?.length > 0)) {
                                continue;
                            }

                            // Check if we already have this asset
                            if (this.assets?.length > 0) {
                                const assetIdx = this.assets.findIndex(x => x.id === asset.id);
                                if (assetIdx >= 0) {
                                    continue;
                                }
                            }

                            // Set asset
                            this.assets.push(asset);
                        }
                    }

                    // Empty new attachments
                    this.attachments = [];
                    this.tmpAssets = [];

                });
            },
            createMessage() {

                // Verify eligible to send
                if (!this.eligibleToSend) {
                    return;
                }

                // Init
                const options = {
                    account_id: this.conversationGroup.account_id,
                    body: this.newMessageBody,
                    campaign_customer_active_id: this.currentCampaignCustomer?.campaign_customer_active_id || 0,
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id || 0,
                    campaign_id: this.currentCampaignCustomer?.campaign_id || 0,
                    conversation_group_id: this.conversationGroup.conversation_group_id,
                    customer_phone_number_id: this.customerPhoneNumberId || 0,
                    assets: this.assets || [],
                    override_campaign_customer_id: null,
                }

                // Set campaign IDs if all null
                if (this.otherCampaignCustomerId > 0 && (options?.campaign_customer_active_id || 0) === 0 && (options?.campaign_customer_id || 0) === 0 && (options?.campaign_id || 0) === 0) {
                    options.override_campaign_customer_id = this.otherCampaignCustomerId;
                }

                this.newMessageBody = null;
                this.assets = [];
                this.isSending = true;
                this.displayEmojiPicker = false;
                CreateMessage(options).then((response) => {
                    if (response.Data?.message?.id > 0) {

                        // Only create agent if valid function
                        // Main sms screen will have this, customer phone number softphone usage won't
                        if (this.validCreateAgent) {
                            this.createAgentForSentMessage(response.Data.message);
                        }
                    }

                    this.isSending = false;
                });
            },
            getCommunicationCenterSmsMessageSoftphone(isResync) {

                const options = {
                    campaign_customer_active_id: (this.currentCampaignCustomer?.campaign_customer_active_id || 0),
                    account_id: (this.accountId || this.currentCampaignCustomer?.account_id),
                    campaign_id: (this.campaignId || 0), // this field is only used for ad hoc softphone, so no cc active will be present
                    customer_phone_number_id: (this.customerPhoneNumberId || 0),
                    incoming_phone_number_id: (this.incomingPhoneNumberId || 0),
                    other_campaign_customer_id: (this.otherCampaignCustomerId || 0),
                }

                this.isLoadingConversations = true;
                GetCommunicationCenterSmsMessageSoftphone(options).then((response) => {
                    if (!!response.Data?.softphone) {

                        // In case of re-sync, check if we already have a conversation group and if so, if the last message is different
                        const lastMessageTime = this.conversationGroup?.last_message_time;

                        // Set
                        this.conversationGroup = response.Data.softphone?.conversation_group;
                        if (this.conversationGroup?.conversations?.length > 0) {
                            this.lastConversationID = this.conversationGroup.conversations[0].conversation_id;
                        }

                        this.messageTemplates = response.Data.softphone?.message_templates;
                        this.messageTemplateTags = response.Data.softphone?.message_template_tags;
                        this.buildTagMenu();

                        // If the re-sync got us some missed messages, play incoming audio sound so the user knows new messages are here
                        if (isResync === true && !!this.conversationGroup?.last_message_time && lastMessageTime !== this.conversationGroup?.last_message_time) {
                            this.playIncomingMessageAudio();
                        }

                        this.readAllConversationGroupUnreadMessages(this.conversationGroup.conversation_group_id, this.conversationGroup.account_id);
                    }
                    this.isLoadingConversations = false;

                    Vue.nextTick(() => { this.scrollToBottom(); });
                });
            },
            getConversationGroupConversations() {

                if (!(this.conversationGroup?.conversation_group_id > 0 && this.conversationGroup?.customer_id)) {
                    return
                }

                // if there's no last conversation id, abort
                if (!(this.lastConversationID > 0)) {
                    return;
                }

                // If currently loading conversations or not ready to lazy load, abort
                if (this.isLoadingConversations || !this.readyToLazyLoadPreviousConversations) {
                    return;
                }

                let prevScrollHeight = 0;
                const container = this.$refs?.conversationGroupContainer;
                if (!!container && !!container.$el) {
                    prevScrollHeight = container.$el.scrollHeight;
                }

                const options = {
                    last_conversation_id:  this.lastConversationID || 0,
                    account_id:            this.conversationGroup?.account_id || 0,
                    customer_id:           this.conversationGroup?.customer_id || 0,
                    conversation_group_id: this.conversationGroup?.conversation_group_id || 0,
                    organization_id:       this.conversationGroup?.organization_id || 0,
                }

                this.isLoadingConversations = true;
                GetConversationGroupConversations(options).then((response) => {

                    // Use set timeout to improve ui/ux experience. Helps with high scroll speed and indicates to user that loading previous messages
                    setTimeout(() => {

                        if (response?.Data?.conversations?.length > 0) {

                            if (!(this.conversationGroup.conversations?.length > 0)) {
                                this.conversationGroup.conversations = response.Data.conversations;
                            } else {
                                // Add conversations to beginning of array (loading previous conversations)
                                this.conversationGroup.conversations.unshift(...response.Data.conversations);
                            }
                            this.lastConversationID = this.conversationGroup.conversations[0].conversation_id;
                            
                            Vue.nextTick(() => { 
                                
                                // if list container is found, hold scroll position in list
                                if (!!container && !!container.$el) {
                                    let scrollTo = container.$el.scrollHeight - prevScrollHeight;
                                    if (scrollTo > 75) {
                                        // scroll up a little to show a little bit of loaded previous messages and hold position from progress loader
                                        scrollTo = scrollTo - 55;
                                    }
                                    container.$el.scrollTop = scrollTo;
                                }

                                this.onScrollConversationMessages();
                            });
                        } else {

                            // When all conversations have been loaded
                            this.lastConversationID = -1
                        }

                        this.isLoadingConversations = false;

                    }, 1000)
                });
            },
            handleMessageBodyInputKeydown(e) {
                
                // If the user presses the enter button, submit message
                if (e.key?.toLowerCase() === "enter") {
                    this.createMessage();
                    e.preventDefault();
                }
            },
            makeNewMessageTextareaFocused() {
                this.newMessageTextarea?.focus();
            },
            onBlurNewMessageBody() {
                let cursorStartPosition = 0;
                let cursorEndPosition = 0;

                if (this.newMessageBody?.length > 0) {
                    cursorStartPosition = this.newMessageTextarea?.selectionStart || 0;
                    cursorEndPosition = this.newMessageTextarea?.selectionEnd || 0;
                }

                this.newMessageTextareaCursorStartPosition = cursorStartPosition;
                this.newMessageTextareaCursorEndPosition   = cursorEndPosition;
            },
            onMediaFileUpload(files) {

                if (!(files?.length > 0)) {
                    return;
                }

                for (let i = 0; i < files.length; i++) {

                    const file = files[i];
                    if (file?.type?.includes("image/")) {
    
                        if (file.size >= 5000000) {
                            this.showSnackbar("error", 'Image size must be less than 5 MB')
                            return;
                        }
    
                        // const reader = new FileReader();
                        // reader.onload = (e) => {
                        //     this.imageUrl = e.target.result;
                        // };
                        // reader.readAsDataURL(file);

                    } else if (file?.size >= 500000) {
                        this.showSnackbar("error", 'Attachment size must be less than 500 KB')
                        return;
                    }

                    this.attachments.push(file);
                    this.tmpAssets.push({ isTemp: true });
                }


                this.createAsset();

                // Permits uploading same file again (in case they add/remove/add)
                this.fileUploaderKey = Date.now();
            },
            onNewMessage(response) {

                // Set message and verify presence
                const message = response?.Data;
                if (!this.conversationGroup || !message || !(message?.message_id > 0) || !(message?.conversation_id > 0)) {
                    return;
                }

                // Verify that the message's conversation group ID matches the current group
                // If it doesn't, this realtime message can be ignored
                if (this.conversationGroup.conversation_group_id !== message.conversation_group_id) {
                    return;
                }

                // Set conversations to empty array if null
                if (!(!!this.conversationGroup.conversations && this.conversationGroup.conversations?.length > 0)) {
                    this.conversationGroup.conversations = [];
                }

                // Attempt to find conversation.
                const conversationIdx = this.conversationGroup.conversations.findIndex(i => i.conversation_id === message.conversation_id);
                
                // If the conversation already exists, append the message to the conversation
                if (conversationIdx >= 0) {
                    
                    // Init conversation and either push message onto array of messages or init a new array of messages
                    const conversation = this.conversationGroup.conversations[conversationIdx];
                    if (!conversation || !(conversation.conversation_id > 0)) {
                        return;
                    }

                    // Set messages to empty array if null
                    if (!(!!conversation.messages && conversation.messages?.length > 0)) {
                        conversation.messages = [];
                    }

                    // Verify message isn't already in array
                    const messageIdx = conversation.messages.findIndex(i => i.message_id === message.message_id);
                    if (messageIdx >= 0) {
                        return;
                    }

                    // Push message onto array and sort
                    conversation.messages.push(message);
                    this.sortConversationMessages(conversation);

                } else { // Since the message belongs to a new conversation, setup conversation and append message

                    // Init new conversation from message
                    const conversation = {
                        conversation_id: message.conversation_id,
                        conversation_key: message.conversation_key,
                        conversation_group_id: message.conversation_group_id,
                        conversation_group_person_id: message.conversation_group_person_id,
                        created_at: message.conversation_created_at,
                        friendly_created_at: message.conversation_friendly_created_at,
                        is_active: true,
                        last_message_id: message.message_id,
                        last_message_status: message.last_message_status || 'Delivered',
                        messages: [message],
                    };

                    // Push conversation onto array and sort
                    this.conversationGroup.conversations.push(conversation);
                    this.sortConversationGroupConversations();
                }
                
                this.readAllConversationGroupUnreadMessages(this.conversationGroup.conversation_group_id, this.conversationGroup.account_id);
                Vue.nextTick(() => { this.scrollToBottom(); });
            },
            onScrollConversationMessages() {
                if (!this.readyToLazyLoadPreviousConversations) {
                    return;
                }

                const container = this.$refs?.conversationGroupContainer;
                if (!!container && !!container.$el) {
                    // on scroll within 25 px to top, check for/load previous conversations
                    if (container.$el.scrollTop < 25) {
                        this.getConversationGroupConversations();
                    }
                }
            },
            readAllConversationGroupUnreadMessages(conversationGroupID, accountID) {
                const options = {
                    account_id: accountID,
                    conversation_group_id: conversationGroupID,
                }

                ReadAllConversationGroupUnreadMessages(options).then(() => {
                });

                return;
            },
            removeAsset(asset) {
                if (!asset) {
                    return;
                }

                const idxToRemove = this.assets.findIndex(x => x.id === asset.id);
                if (idxToRemove >= 0) {
                    this.assets.splice(idxToRemove, 1);
                }
            },
            resyncSoftphone() {
                this.getCommunicationCenterSmsMessageSoftphone(true);
            },
            scrollToBottom() {
                const container = this.$refs?.conversationGroupContainer;
                if (!!container && !!container.$el) {
                    container.$el.scrollTop = container.$el.scrollHeight;
                }

                // Set ready for lazy load and check if at top of list (i.e. unable to scroll)
                this.readyToLazyLoadPreviousConversations = true;
                this.onScrollConversationMessages();
            },
            selectEmoji(emoji) {
                
                // If null, need to set to empty first
                if (this.newMessageBody === null) {
                    this.newMessageBody = "";
                }

                // Insert emoji
                this.newMessageBody = this.newMessageBody.substring(0, this.newMessageTextareaCursorStartPosition) + emoji + this.newMessageBody.substring(this.newMessageTextareaCursorEndPosition);
                
                // Move cursor position to after emoji
                this.newMessageTextareaCursorStartPosition += emoji?.length || 0;

                // If text was highlighted to be replaced by emoji, reset highlighting since replaced with emoji with first insert
                this.newMessageTextareaCursorEndPosition = this.newMessageTextareaCursorStartPosition;
            },
            setConversationGroupHeight() {
                const toolbarHeight = 54;
                const cardPadding = 12;
                const footerHeight = (this.$refs?.smsMessageSoftphoneFooterCol?.clientHeight || 78);
                this.conversationGroupHeight = (this.softPhoneHeight - toolbarHeight - cardPadding - footerHeight);
            },
            sortConversationGroupConversations() {
                if (this.conversationGroup?.conversation_group_id > 0 && this.conversationGroup?.conversations?.length > 0) {
                    this.conversationGroup.conversations = this.conversationGroup.conversations.sort(function(x, y) {
                        return x.conversation_id - y.conversation_id;
                    });

                    // Set last conversation id
                    this.lastConversationID = this.conversationGroup.conversations[0].conversation_id;
                }
            },
            sortConversationMessages(conversation) {
                if (conversation?.conversation_id > 0 && conversation?.messages?.length > 0) {
                    conversation.messages = conversation.messages.sort(function(x, y) {
                        return x.message_id - y.message_id;
                    });
                }
            },
            sortMenuTags() {
                if (this.menuTags?.length > 0) {
                    this.menuTags = this.menuTags.sort(function(x, y) {
                        return (x?.name || "").localeCompare(y?.name || "");
                    });
                }
            },
            translateMessage(language: string) {
                if (this.newMessageBody === "" || this.newMessageBody == null || language === "") {
                    return
                }

                const options = {
                    language: language,
                    message: this.newMessageBody,
                }

                CreateTranslation(options).then((response) => {
                    if (response?.Data?.message?.length > 0) {
                        this.newMessageBody = response.Data.message;
                    } else {
                        let err = "Unable to translate message"
                        if (response?.Error?.length > 0) {
                            err = response.Error
                        }
                        this.showSnackbar("error", err);
                    }

                    this.displayTranslationPicker= false;
                });
            }
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            eligibleToSend() {
                // If both the message body and attachment are empty, abort
                if (!(this.newMessageBody?.length > 0) && !(this.assets?.length > 0)) {
                    return false;
                }

                // If conversation group is invalid, abort
                if (!this.conversationGroup) {
                    return false;
                }

                // Handle max 10 attachments
                if (this.assets?.length > 10) {
                    return false;
                }

                return true;
            },
            isMmsEnabled() {
                return this.currentUser?.account?.communication_center_mms_enabled === true;
            },
            newMessageTextarea() {
                return this.$refs.newMessageTextarea?.$refs?.input;
            },
            renderMessageTemplateMenu() {
                return (this.messageTemplates?.length > 0 && this.menuTags?.length === 0);
            },
            renderTagMenu() {
                return (this.menuTags?.length > 0);
            },
            textAreaErrorMessages() {
                if (this.assets?.length > 10) {
                    return ["10 attachments max"]
                }

                return [];
            },
            validAssets() {
                return this.assets?.length > 0;
            },
            validConversations() {
                return this.conversationGroup?.conversations?.length > 0;
            },
            validCreateAgent() {
                return this.createAgent !== undefined && this.createAgent !== null;
            },
            validCustomerConversationGroupPersonPhoneNumber() {
                return this.conversationGroup?.customer_conversation_group_person_id > 0 && this.conversationGroup?.customer_conversation_group_person_phone_number?.length > 0;
            },
            validExit() {
                return this.exit !== undefined && this.exit !== null;
            },
            validTmpAssets() {
                return this.tmpAssets?.length > 0;
            },
        },
        components: {
            Attachment,
            AttachmentSlideGroup,
            ConversationMessages,
            EmojiPicker,
            MessageTemplateMenu,
            StrLimitTooltipText,
            TagMenu,
            TranslationPicker,
        },
        props: {
            accountId: Number,
            campaignId: Number,
            createAgent: Function,
            currentCampaignCustomer: Object,
            customerId: Number,
            customerPhoneNumberId: Number,
            exit: Function,
            incomingPhoneNumberId: Number,
            isRacOnline: Boolean,
            otherCampaignCustomerId: Number,
            playIncomingMessageAudio: Function,
            showSnackbar: Function,
            softPhoneHeight: Number,
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "newCustomerAndIncomingPhoneNumberMessage" }}, this.onNewMessage);
        },
        created() {
            this.getCommunicationCenterSmsMessageSoftphone(false);
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "newCustomerAndIncomingPhoneNumberMessage" }}, this.onNewMessage);
        },
        mounted() {
            this.setConversationGroupHeight();
        },
        watch: {
            assets() {
                setTimeout(() => {
                    Vue.nextTick(() => { this.setConversationGroupHeight(); });
                }, 100)
            },
            customerPhoneNumberId() {
                this.conversationGroup = null;
                this.messageTemplates = null;
                this.messageTemplateTags = null;

                Vue.nextTick(() => {
                    this.getCommunicationCenterSmsMessageSoftphone(false);
                });
            },
            isRacOnline() {
                
                // If it changed and now is true again, need to re-sync their softphone
                if (this.isRacOnline === true) {
                    this.resyncSoftphone();
                }
            },
            newMessageBody() {
                Vue.nextTick(() => { this.setConversationGroupHeight(); });
            },
            softPhoneHeight() {
                Vue.nextTick(() => { this.setConversationGroupHeight(); });
            },
            tmpAssets() {
                setTimeout(() => {
                    Vue.nextTick(() => { this.setConversationGroupHeight(); });
                }, 100)
            },
        },
    });
</script>

<style lang="scss" scoped>
</style>
