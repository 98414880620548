<template lang="pug">
    v-app
        template(v-if="validRoute")
            VolieMainMenu(v-if="!hideMainMenu")
            VolieMainTopNav

            v-main
                router-view(:key="$route.fullPath")

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';
    import VolieMainMenu from '@/components/shared/volie_main_menu.vue';
    import VolieMainTopNav from '@/components/shared/volie_main_top_nav.vue';

    // Data
    import { CreateUserClientDrop } from "@/data";

    //- template(v-else) todo - add volie logo above and loader so user doesn't just see blank screen

    export default Vue.extend({
        name: 'App',
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                isRacOnline: true,
                isWindowOnline: true,
            }
        },
        methods: {
            createUserClientDrop(dropType) {

                // Init
                const options = {
                    drop_type: dropType,
                    url: window?.location?.pathname,
                }

                CreateUserClientDrop(options).then(() => {
                });
            },
            handleWindowOnline() {
                this.isWindowOnline = true;
            },
            handleWindowOffline() {
                this.isWindowOnline = false;
            },
            onClientStateChange(isOpen) {
                this.isRacOnline = isOpen;
            },
        },
        computed: {
            hideMainMenu() {
                return (this.$route?.meta?.hideMainMenu === true ? true : false);
            },
            validRoute() {
                return this.$route?.name?.length > 0;
            },
        },
        components: {
            VolieMainMenu,
            VolieMainTopNav,
        },
        destroyed() {
            this.bus.unsubscribe({ type: "clientState" }, this.onClientStateChange);
            window.removeEventListener('online', this.handleWindowOnline);
            window.removeEventListener('offline', this.handleWindowOffline);
        },
        created() {
            this.bus.subscribe({ type: "clientState" }, this.onClientStateChange);
        },
        mounted() {
            window.addEventListener('online', this.handleWindowOnline);
            window.addEventListener('offline', this.handleWindowOffline);
        },
        watch: {
            isRacOnline() {

                // If it changed and now is true again, create client drop record
                if (this.isRacOnline === true) {
                    this.createUserClientDrop("rac")
                }
            },
            isWindowOnline() {

                // If it changed and now is true again, create client drop record
                if (this.isWindowOnline === true) {
                    this.createUserClientDrop("window")
                }
            },
        },
    });
</script>

<style lang="scss">
    @import './styles/styles.scss';
</style>
