<template lang="pug">

    div
        v-hover(v-slot:default='{ hover }' v-for='activity in activities' :key='activity.ticket_activity_id')

            v-row.no-margin.dashed-border
                v-col.col-8(v-if="activity.ticket_reopened")
                    strong Reopened ticket
                    v-container(v-if="activity.comments_message" style="padding: 12px 0;" v-html="activity.comments_message")

                v-col.col-8(v-if="!activity.ticket_reopened")
                    v-col.no-padding
                        em(v-if="activity.title_changed") Updated title
                    v-col.no-padding
                        em(v-if="activity.description_changed") Updated description
                    v-col.no-padding
                        em(v-if="activity.ticket_assignment_changed") Assigned ticket to {{ activity.supporter }}
                    v-col.no-padding
                        em(v-if="activity.ticket_type_changed") Updated ticket type to {{ activity.ticket_type }}
                    v-col.no-padding
                        em(v-if="activity.priority_changed") Updated priority to {{ activity.priority }}
                    v-col.no-padding
                        em(v-if="activity.current_status_changed") Updated status to {{ activity.current_status }}
                    v-col.no-padding
                        em(v-if="activity.completion_code_changed") Updated completion code to {{ activity.completion_code }}
                    v-col.no-padding
                        em(v-if="activity.internal_development_estimate_hours_changed") Updated development estimate hours to {{ activity.internal_development_estimate_hours }}
                    v-col.no-padding
                        em(v-if="activity.internal_development_actual_hours_changed") Updated development actual hours to {{ activity.internal_development_actual_hours }}
                    v-col.no-padding
                        em(v-if="activity.internal_development_priority_changed") Updated development priority to {{ activity.internal_development_priority }}
                    v-col.no-padding
                        em(v-if="activity.internal_development_size_changed") Updated development size to {{ activity.internal_development_size }}
                    v-col.no-padding
                        em(v-if="activity.internal_development_stage_changed") Updated development stage to {{ activity.internal_development_stage }}
                    v-col.no-padding
                        em(v-if="activity.mediaable_id") Uploaded attachment
                    v-col.no-padding
                        em(v-if="activity.comments_message") Comment added
                    v-col.no-padding
                        v-row(v-if="activity.escalated_to_engineering_changed" style="margin: 0px;")
                            em Ticket Escalated to Engineering - 
                            a.ml-2(:href="'https://volie.atlassian.net/browse/' + activity.jira_issue_key") {{ ' ' + activity.jira_issue_key }}
                    v-col.no-padding
                        v-container(v-if="activity.comments_message" style="padding: 12px 0px 0px;" v-html="activity.comments_message")
                    
                    template(v-if="isSourceAdmin")
                        v-col.no-padding(v-show='!!hover')
                            v-btn(
                                v-if="activity.comments_message"
                                small
                                color='warning'
                                dark
                                @click="onUpdateComment(activity)"
                            )
                                v-icon(small) mdi-comment-edit-outline
                            v-btn(
                                v-else
                                small
                                color='info'
                                dark
                                @click="onUpdateComment(activity)"
                                style="margin-top: 12px;"
                            )
                                v-icon(small) mdi-comment-plus-outline

                v-col.col-4
                    v-row.no-margin
                        v-col.activity-cell
                            strong {{ activity.display_name }}

                    v-row.no-margin
                        v-col.activity-cell
                            strong {{ activity.friendly_created_at }}

                v-col.col-10(v-if="activity.mediaable_id")
                    a(
                        v-if="activity.asset_file_type === 'image'" 
                        :href="activity.asset_url" 
                        target="_blank"
                    )
                        v-img(
                            v-if="activity.asset_file_type === 'image'"
                            contain
                            max-height='250'
                            max-width='250'
                            :src='activity.asset_url'
                        )
                    a(
                        v-else 
                        :href="activity.asset_url" 
                        target="_blank"
                    )
                        | {{ activity.original_file_name }}


</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                formTag: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            onUpdateComment(activity) {
                if (this.updateComment) {
                    this.updateComment(activity)
                }
            },
        },
        computed: {
            isSourceAdmin() {
                return this.source === "admin";
            },
        },
        props: {
            activities: Array,
            source: String,
            updateComment: Function,
        },
        created() {},
    });
</script>

<style lang="scss" scoped>
.no-margin {
    margin: 0;
}
.no-padding {
    padding: 0;
}
.activity-cell {
    text-align: right !important;
    padding: 6px 0;
    font-size: 12px;
}
.dashed-border {
    border-bottom: 1px dashed #cfdadd;
}
</style>