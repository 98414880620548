<template lang="pug">
    v-row
        v-col.p-2
            v-chip(@click="translateMessage('english');" color="grey" text-color="white")
                | English
        v-col.p-2
            v-chip(@click="translateMessage('french');" color="grey" text-color="white")
                | French
        v-col.p-2
            v-chip(@click="translateMessage('german');" color="grey" text-color="white")
                | German
        v-col.p-2
            v-chip(@click="translateMessage('spanish');" color="grey" text-color="white")
                | Spanish
    </template>

<script lang="ts">
import Vue from 'vue';

// Components

export default Vue.extend({
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {

    },
    components: {

    },
    props: {
        campaign: Object,
        translateMessage: Function,
    },
    watch: {

    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>