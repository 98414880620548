import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import { crashDetector } from "./crash_detector";
import volieStore from './store';
import router from './router';
import SharedLinkApp from './shared_link/App.vue';
import SharedLinkRouter from './shared_link/router';
import vuetify from './plugins/vuetify';
import filters from "./filters";
import VueTimeago from 'vue-timeago';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css'
import { NotAuthorized, cacheGet, cacheSet, validToken } from './data';
import VueRouter from 'vue-router';

const devMode = process.env.NODE_ENV !== 'production';
const prodMode = process.env.NODE_ENV === 'production';
Vue.config.productionTip = devMode;
Vue.config.devtools = devMode;

Vue.config.errorHandler = function(err, vm, info) {

    sessionStorage.setItem('error_event', JSON.stringify(err, Object.getOwnPropertyNames(err)));

}

const sharedLinkMode = window.location.pathname.startsWith("/s/");
const load = true;

// (function checkAuth(){
//     if (sharedLinkMode) { return }
//     const urlParams = new URLSearchParams(window.location.search);
//     const token = urlParams.get('token');
//     const redirectTo = urlParams.get('redirect_to');
//     if (token) {
//         cacheSet("token", token)
//         if (redirectTo) {
//             window.location.assign(redirectTo);
//         } else {
//             window.location.assign("/");
//         }
//     } else {
//         const tokenCheck = cacheGet<string>("token");
//         if (!validToken(tokenCheck)) {
//             cacheSet("token", null)
//             load = false;
//             window.location.replace("/auth/ab/login?redir=" + window.location.href);
//         }
//     }
// })();

declare global {
    interface Window {
        Beamer: {
            show: () => void;
            hide: () => void;
        };
    }
}

declare global {
    interface Window {
        beamer_config?: {
            // Core Parameters
            product_id: string;
            language?: string;
            display?: "minimized" | "maximized" | "hidden"; // Default: "minimized"
            top?: boolean; // Default: false (position at the bottom of the screen)
            button?: boolean; // Default: true (show the Beamer button)
            lazy_load?: boolean; // Default: false (load Beamer on interaction)

            // User-Specific Parameters
            user_id?: string; // Unique identifier for the user
            user_email?: string; // Optional email for the user
            user_name?: string; // Optional name of the user
            user_properties?: { [key: string]: any }; // Custom user properties for segmentation

            // Behavioral Parameters
            auto_open?: boolean; // Default: false (doesn't auto-open on page load)
            notify?: boolean; // Default: true (show notification badges)
            hide_unread_count?: boolean; // Default: false (show unread count)
            force_unread_count?: boolean; // Default: false (force unread count to show)

            // Customization Parameters
            icon?: string; // URL to a custom icon for the Beamer button
            color?: string; // Hex color code for the button and widget theme
            position?: "right" | "left"; // Default: "right" (position of the Beamer button)

            // Filter
            filter?: string;

            // Event Hooks (optional callback functions)
            onOpen?: () => void; // Callback when the Beamer feed is opened
            onClose?: () => void; // Callback when the Beamer feed is closed
            onShow?: () => void; // Callback when a post is shown
            onRead?: () => void; // Callback when a post is read
            onNotificationClick?: () => void; // Callback when the user clicks a notification badge
        };
    }
}

if (load) {

    window.onerror = (a, b, c, d, e) => {
        sessionStorage.setItem('error_event', JSON.stringify(e, Object.getOwnPropertyNames(e)));
    };

    sessionStorage.setItem('log_xhr', 'false');

volieStore.load(sharedLinkMode).then(() => {

    const mixin = {
        methods: {}
    }
    Vue.mixin(mixin);
    filters();

    const store = volieStore.store;

    const dsn = process.env.VUE_APP_SENTRY_URL;
    if (prodMode && dsn) {
        Sentry.init({
          Vue: Vue,
          dsn: process.env.VUE_APP_SENTRY_URL,
          integrations: [new Integrations.BrowserTracing()],
          logErrors: true,

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,

          // ignoreErrors:[
          //   "Non-Error exception captured",
          //   "Non-Error promise rejection captured"
          // ],
        });

        const user = volieStore.currentUser();

        if (user) {
            Sentry.setUser({
                id: user.user_id,
                email: user.email,
            })
            if (user.log_crashes) {
                crashDetector.log = true;
            }
        }
    }

    if (sharedLinkMode) {
      new Vue({
          router: SharedLinkRouter,
          store,
          vuetify,
          render: h => h(SharedLinkApp)
      }).$mount('#app')
    } else {

      crashDetector.load();
      new Vue({
          router,
          store,
          vuetify,
          render: h => h(App)
      }).$mount('#app')
    }

    (function(d, s, id) {
        if (!(process.env.VUE_APP_VOLIE_BEAMER_KEY?.length > 0)) {
            return
        }

        let js;
        const bjs = d.getElementsByTagName(s)[0];
        const currentUser = volieStore.currentUser();
        if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.src = "https://app.getbeamer.com/js/beamer-embed.js";
            bjs.parentNode.insertBefore(js, bjs);
    
            window.beamer_config = {
                product_id: process.env.VUE_APP_VOLIE_BEAMER_KEY,
                language: null,
                notify: true,
                filter: "Internal;Dealer Management;Dealer Agent",
            };

            if (currentUser?.user_id > 0) {
                window.beamer_config.user_id = currentUser.user_id;
            }
        }
    
    })(document, "script", "beamer-js");

}).catch((result) => {
        if (result == NotAuthorized) {
            window.location.replace("/auth/ab/login?redir=" + window.location.href);
        }
});


Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: undefined, // Default locale
    locales: {
        'zh-CN': require('date-fns/locale/zh_cn'),
        'ja': require('date-fns/locale/ja'),
    }
});

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi',
})

}

Vue.use(VueRouter)

