import { get, post, put, destroy } from '.';

export function GetMessageTemplates(options = {}) {
    return get<any>("/app/api/message_templates", options);
}

export function DestroyMessageTemplate(id, options = {}) {
    return destroy<any>("/app/api/message_templates/" + (id || 0).toString(), options);
}

export function CreateMessageTemplate(options = {}) {
    return post<any>("/app/api/message_templates", options);
}

export function UpdateMessageTemplate(id, options = {}) {
    return put<any>("/app/api/message_templates/" + (id || 0).toString(), options);
}