import { get, destroy, post } from '.';

export function GetCampaignMessageTemplates(id: number) {
	return get<any>(`/app/api/campaigns/${id}/campaign_message_templates`);
}

export function GetAvailableMessageTemplates(id: number) {
	return get<any>(`/app/api/campaigns/${id}/message_templates`);
}

export function CreateCampaignMessageTemplate(id: number, options = {}) {
	return post<any>(`/app/api/campaigns/${id}/campaign_message_templates`, options);
}

export function DeleteCampaignMessageTemplate(id: number, options = {}) {
	return destroy<any>(`/app/api/campaign_message_templates/${id}`, options);
}