<template lang="pug">
    v-tooltip(top)
        template(v-slot:activator='{ on, attrs }')
            span(v-bind="attrs" v-on="on" role="text")
                slot(name="text")
                    template(v-if="isBold && isUppercase")
                        strong.text-uppercase(:class="className")
                            | {{ (text || '').trim() }}
                    template(v-else-if="isBold")
                        strong(:class="className")
                            | {{ (text || '').trim() }}
                    template(v-else-if="isUppercase")
                        .text-uppercase(:class="className")
                            | {{ (text || '').trim() }}
                    template(v-else)
                        span(:class="className")
                            | {{ (text || '').trim() }}
        span
            | {{ displayTooltip }}
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            displayTooltip() {
                if (this.altTooltip?.length > 0) {
                    return this.altTooltip;
                }

                return this.text;
            },
        },
        props: {
            altTooltip: String,
            className: String,
            isBold: Boolean,
            isUppercase: Boolean,
            text: String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
